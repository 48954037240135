<template xmlns="http://www.w3.org/1999/html">
  <div class="lg:grid grid-flow-col grid-cols-2 grid-rows-1 lg:gap-x-6">
    <div class="bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg pb-6">
      <div class="p-6 flex space-x-4 items-center justify-between">
        <div class="flex space-x-4 items-center">
          <h4 class="text-xl font-black">Lead Information</h4>
        </div>
      </div>
      <div class="px-4 sm:px-6 -mt-1">
        <dl class="w-full">
          <div v-if="usesGroupLeadFields" class="flex space-x mb-2">
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'company'})">
              <label class="block input-label text-gray-600">Company</label>
              <input :value="lead.company ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 font-bold" readonly type="text">
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'first_name'})">
              <label class="input-label block text-gray-600">
                <span v-if="usesGroupLeadFields">Benefits Contact First</span>
                <span v-else>Primary First</span>
              </label>
              <input :value="lead.first_name ?? '—'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
            </div>
          </div>
          <div class="flex space-x-4">
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'last_name'})">
              <label class="block input-label text-gray-600">
                <span v-if="usesGroupLeadFields">Benefits Contact Last</span>
                <span v-else>Primary Last</span>
              </label>
              <input :value="lead.last_name ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                     readonly type="text">
            </div>
          </div>
          <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'nickname'})">
                <label class="input-label font-medium text-gray-600">Nickname</label>
                <input :value="lead.nickname ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 block"
                       readonly type="text">
                <div class="flex items-center">
                  <label for="use_nickname" class="block text-gray-600 mr-1 mt-1 text-sm">Use Nickname on Templates {{lead.use_nickname_on_templates == 1 ? '(Yes)' : '(No)'}}</label>
                </div>
          </div>
          <!-- hide requrested by leb
            <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'dob'})">
                <label class="input-label font-medium text-gray-600">Primary Date of Birth</label>
                <input :value="lead.formatted_dob ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 block"
                       readonly type="text">
            </div>-->
        </dl>
      </div>

        <div class="px-4 sm:px-6 mt-4">
            <dl class="w-full">
                <div class="space-y-4">
                    <div class="flex items-center space-x-2" v-if="usesGroupLeadFields">
                        <div class="cursor-pointer w-full">
                            <div class="input-label text-gray-600 flex items-center justify-start">
                              <label class="mr-2">Title/Notes (First Contact)</label>
                            </div>
                            <textarea readonly rows="2" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" @click="$emit('show-edit-mode', {fieldId: 'title_notes_first_contact'})">{{ lead.title_notes_first_contact }}</textarea>
                        </div>
                    </div>
                    <div>
                      <div>
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="mr-2">
                            <span v-if="usesGroupLeadFields">Phone (First Contact)</span>
                            <span v-else>Phone</span>
                          </label>
                          <div class="flex items-center">
                            <button v-if="lead.phone && !isDnc && !hst_age_locked" class="btn-secondary p-1" type="button" @click.stop="showComposeTextForm({leadId: lead.id, phone: 1})">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                              </svg>
                            </button>
                            <a v-if="lead.phone && clicktocall && !isDnc && !hst_age_locked" :href="clicktocallPhone1Route" class="btn-secondary px-1 py-0.5 ml-2 inline-block" type="button">
                              <div class="flex items-center">
                                <img v-if="clicktocall === 'TBComm'" class="h-5 w-5" src="../../../images/tbcomm.png" >
                                <span class="text-sm">{{ clicktocall }}</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="cursor-pointer w-1/5" @click.prevent="$emit('show-edit-mode', {fieldId: 'phone_type'})">
                          <input :value="lead.phone_type ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 capitalize"
                                 readonly type="text">
                        </div>
                        <div class="cursor-pointer">
                          <input :value="this.formatPhone(lead.phone) ?? '—'" @click="$emit('show-edit-mode', {fieldId: 'phone'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                                 readonly type="text">
                        </div>
                        <div class="cursor-pointer w-1/3" @click.prevent="$emit('show-edit-mode', {fieldId: 'phone1_owner_id'})">
                          <input :value="lead.phone1_owner_name ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 capitalize"
                                 readonly type="text">
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="mr-2">
                            <span v-if="usesGroupLeadFields">Phone 2 (First Contact)</span>
                            <span v-else>Phone 2</span>
                          </label>
                          <div class="flex items-center">
                            <button v-if="lead.phone_2 && !isDnc && !hst_age_locked" class="btn-secondary p-1" type="button" @click.stop="showComposeTextForm({leadId: lead.id, phone: 2})">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 01.865-.501 48.172 48.172 0 003.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0012 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018z" />
                              </svg>
                            </button>
                            <a v-if="lead.phone_2 && clicktocall && !isDnc && !hst_age_locked" :href="clicktocallPhone2Route" class="btn-secondary px-1 py-0.5 ml-2 inline-block" type="button">
                              <div class="flex items-center">
                                <img v-if="clicktocall === 'TBComm'" class="h-5 w-5" src="../../../images/tbcomm.png" >
                                <span class="text-sm">{{ clicktocall }}</span>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="flex items-center space-x-2">
                        <div class="cursor-pointer w-1/5" @click.prevent="$emit('show-edit-mode', {fieldId: 'phone_2_type'})">
                          <input :value="lead.phone_2_type ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 capitalize"
                                 readonly type="text">
                        </div>
                        <div class="cursor-pointer">
                          <input :value="this.formatPhone(lead.phone_2) ?? '—'" @click.prevent="$emit('show-edit-mode', {fieldId: 'phone_2'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                                 readonly type="text">
                        </div>
                        <div class="cursor-pointer w-1/3" @click.prevent="$emit('show-edit-mode', {fieldId: 'phone2_owner_id'})">
                          <input :value="lead.phone2_owner_name ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 capitalize"
                                 readonly type="text">
                        </div>
                      </div>
                    </div>
                </div>
                <div class="space-y-3 mt-3">
                  <div>
                    <div>
                      <div class="flex items-center space-x-2">
                        <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'email'})">
                          <label class="block input-label text-gray-600 mr-1">
                            <span class="mr-2">
                              <span v-if="usesGroupLeadFields">Email 1 (First Contact)</span>
                              <span v-else>Email 1</span>
                            </span>
                            <button v-if="!isDnc && !hst_age_locked" class="btn-secondary p-1 inline-block" type="button" @click.stop="showComposeEmailForm({leadId: lead.id, email: 1})">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                              </svg>
                            </button>

                            <a v-if="lead.email && !isDnc && !hst_age_locked" class="btn btn-primary p-1 text-xs ml-2 mt--1" type="button" @click.stop :href="`mailto:${lead.email}`">Email via Your App</a>
                            <label v-else class="input-label text-gray-600 flex items-center justify-start">
                                <span class="mr-2">
                                  &nbsp;
                                </span>
                            </label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center space-x-2">
                      <div class="w-2/3 cursor-pointer" @click.prevent="$emit('show-edit-mode', {fieldId: 'email'})">
                        <input :value="lead.email ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                               readonly type="text">
                      </div>
                      <div class="cursor-pointer w-1/3" @click.prevent="$emit('show-edit-mode', {fieldId: 'email1_owner_id'})">
                          <input :value="lead.email1_owner_name ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 capitalize"
                                 readonly type="text">
                      </div>
                  </div>
                  <div>
                    <div>
                      <div class="flex items-center space-x-2">
                        <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'email_2'})">
                          <label class="block input-label text-gray-600">
                                <span class="mr-2">
                                  <span v-if="usesGroupLeadFields">Email 2 (First Contact)</span>
                                  <span v-else>Email 2</span>
                                </span>
                            <button v-if="!isDnc && !hst_age_locked" class="btn-secondary p-1 inline-block" type="button" @click.stop="showComposeEmailForm({leadId: lead.id, email: 2})">
                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                              </svg>
                            </button>
                            <a v-if="lead.email_2 && !isDnc && !hst_age_locked" class="btn btn-primary p-1 text-xs ml-2" type="button" @click.stop :href="`mailto:${lead.email_2}`">Email via Your App</a>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="flex items-center space-x-2">
                      <div class="cursor-pointer w-2/3" @click.prevent="$emit('show-edit-mode', {fieldId: 'email_2'})">
                        <input :value="lead.email_2 ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                               readonly type="text">
                      </div>
                      <div class="cursor-pointer w-1/3" @click.prevent="$emit('show-edit-mode', {fieldId: 'email2_owner_id'})">
                          <input :value="lead.email2_owner_name ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 capitalize"
                                 readonly type="text">
                      </div>
                  </div>

                  <div class="space-y-4" v-if="usesGroupLeadFields">
                    <dl class="w-full">
                      <div class="flex space-x-4 mt-8">
                        <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'first_name_second_contact'})">
                          <label class="input-label block text-gray-600">Second Contact First</label>
                          <input :value="lead.first_name_second_contact ?? '—'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
                        </div>
                        <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'last_name_second_contact'})">
                          <label class="block input-label text-gray-600">Second Contact Last</label>
                          <input :value="lead.last_name_second_contact ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                                 readonly type="text">
                        </div>
                      </div>
                    </dl>
                    <div class="flex items-center space-x-2">
                      <div class="cursor-pointer w-full">
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="mr-2">Title/Notes (Second Contact)</label>
                        </div>
                        <textarea readonly rows="2" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" @click="$emit('show-edit-mode', {fieldId: 'title_notes_second_contact'})">{{ lead.title_notes_second_contact }}</textarea>
                      </div>
                    </div>
                    <div class="flex items-center space-x-2">
                      <div class="cursor-pointer w-full">
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="mr-2">Phone (Second Contact)</label>
                        </div>
                        <input :value="this.formatPhone(lead.phone_second_contact) ?? '—'" @click="$emit('show-edit-mode', {fieldId: 'phone_second_contact'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                               readonly type="text">
                      </div>
                      <div class="cursor-pointer w-full">
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="mr-2">Phone 2 (Second Contact)</label>
                        </div>
                        <input :value="this.formatPhone(lead.phone_2_second_contact) ?? '—'" @click="$emit('show-edit-mode', {fieldId: 'phone_2_second_contact'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                               readonly type="text">
                      </div>
                    </div>
                    <div class="flex items-center space-x-2">
                      <div class="cursor-pointer w-full">
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="mr-2">Email (Second Contact)</label>
                          <button v-if="!isDnc && !hst_age_locked" class="btn-secondary p-1 inline-block" type="button" @click.stop="showComposeEmailForm({leadId: lead.id, email: 3})">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                            </svg>
                          </button>
                          <a v-if="lead.email_second_contact && !isDnc && !hst_age_locked" class="btn btn-primary ml-2 p-1 text-xs" type="button" @click.stop :href="`mailto:${lead.email_second_contact}`">Email via Your App</a>
                        </div>
                        <input :value="lead.email_second_contact" @click="$emit('show-edit-mode', {fieldId: 'email_second_contact'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                               readonly type="text">
                      </div>
                    </div>

                    <!-- BEGIN: Social Media Links (Group Leads) -->
                    <div class="!mt-8">
                      <h4 class="text-xl font-black">Social Media</h4>
                      <div class="mt-2">
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="">Facebook</label>
                          <a v-if="lead.social_media_facebook_id" target="_blank" :href="lead.social_media_facebook_id" class="btn btn-secondary px-1 py-0.5 ml-2 inline-block" type="button">
                            <ExternalLinkIcon class="w-4 h-4" />
                          </a>
                        </div>
                        <div class="flex items-end space-x-2 mt-1">
                          <div class="cursor-pointer w-full">
                            <input :value="lead.social_media_facebook_id" @click="$emit('show-edit-mode', {fieldId: 'social_media_facebook_id'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                                   readonly type="text">
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="">Instagram</label>
                          <a v-if="lead.social_media_instagram_id" target="_blank" :href="lead.social_media_instagram_id" class="btn btn-secondary px-1 py-0.5 ml-2 inline-block" type="button">
                            <ExternalLinkIcon class="w-4 h-4" />
                          </a>
                        </div>
                        <div class="flex items-end space-x-2 mt-1">
                          <div class="cursor-pointer w-full">
                            <input :value="lead.social_media_instagram_id" @click="$emit('show-edit-mode', {fieldId: 'social_media_instagram_id'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                                   readonly type="text">
                          </div>
                        </div>
                      </div>
                      <div class="mt-2">
                        <div class="input-label text-gray-600 flex items-center justify-start">
                          <label class="">LinkedIn</label>
                          <a v-if="lead.social_media_linkedin_id" target="_blank" :href="lead.social_media_linkedin_id" class="btn btn-secondary px-1 py-0.5 ml-2 inline-block" type="button">
                            <ExternalLinkIcon class="w-4 h-4" />
                          </a>
                        </div>
                        <div class="flex items-end space-x-2 mt-1">
                          <div class="cursor-pointer w-full">
                            <input :value="lead.social_media_linkedin_id" @click="$emit('show-edit-mode', {fieldId: 'social_media_linkedin_id'})" class="input-field cursor-pointer border-gray-200 shadow-none mt-1"
                                   readonly type="text">
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- END: Social Media Links (Group Leads) -->
                  </div>
                </div>
            </dl>
        </div>
    </div>

    <div class="bg-white bg-white overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg pb-6 mt-6 lg:mt-0">
      <div class="px-6 pt-6 flex space-x-4 items-center justify-between">
        <div class="flex space-x-4 items-center">
          <h4 class="text-xl font-black">Address Details</h4>
        </div>
      </div>
      <div class="px-6 pb-4 flex space-x-4 items-center justify-between">
        <div class="flex space-x-4 items-center">
          <h4 class="text-lg font-black">Current Leads Time: <span class="text-tb-blue">({{ leadCurrentTime
            }}, {{ lead.timezone_region_name }})</span></h4>
        </div>
      </div>
      <div class="px-4 sm:px-6 -mt-1">
        <dl>
          <div class="cursor-pointer" @click="$emit('show-edit-mode', {fieldId: 'address_1'})">
            <label class="block input-label text-gray-600">Address 1</label>
            <input :value="lead.address_1 ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>
          <div class="cursor-pointer mt-3" @click="$emit('show-edit-mode', {fieldId: 'address_2'})">
            <label class="block input-label text-gray-600">Address 2</label>
            <input :value="lead.address_2 ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>
          <div class="flex w-full space-x-4 items-end mt-3">
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'city'})">
              <label class="block input-label text-gray-600">City</label>
              <input :value="lead.city ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'state_id'})">
              <label class="block input-label text-gray-600">State</label>
              <input :value="lead.state_name ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>
          </div>
          <div class="flex w-full space-x-4 items-end mt-3">
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'zip'})">
              <label class="block input-label text-gray-600">Zip</label>
              <input :value="lead.zip ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'county'})">
              <label class="block input-label text-gray-600">County</label>
              <input :value="lead.county ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>
          </div>
        </dl>
      </div>

      <div class="p-6 flex space-x-4 items-center justify-between">
        <div class="flex space-x-4 items-center">
          <h4 class="text-xl font-black">Additional Details</h4>
        </div>
      </div>
      <div class="px-4 sm:px-6 -mt-1">
        <dl class="w-full">
          <div>
            <div v-if="!usesGroupLeadFields" class="flex items-end w-full space-x-4 items-end">
              <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'company'})">
                <label class="block input-label text-gray-600">Company</label>
                <input :value="lead.company ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
              </div>
            </div>
            <div class="flex items-end w-full space-x-4 items-end">
                <div v-if="lead.has_type_marked_as_group" class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'renewal-date-datepicker-edit-form'})">
                  <label class="block input-label text-gray-600">Renewal Date</label>
                  <input :value="lead.formatted_renewal_date ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
                </div>
                <div v-if="lead.has_type_marked_as_group" class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'number_of_employees'})">
                    <label class="block input-label text-gray-600">Number Of Employees</label>
                    <input :value="lead.number_of_employees ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
                </div>
            </div>

            <div v-if="lead.has_type_marked_as_group" class="flex space-x-4 mt-3">
              <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'fax'})">
                <label class="block input-label text-gray-600">Fax</label>
                <input :value="lead.fax ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
              </div>
              <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'website'})">
                <label class="block input-label text-gray-600">Website</label>
                <input :value="lead.website ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
              </div>
            </div>
          </div>
          <div class="flex items-end space-x-4 mt-3">
            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'annual_household_income'})">
              <label class="block input-label text-gray-600">Annual Household Income</label>
              <input :value="lead.annual_household_income_formatted ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>

            <div v-if="!usesGroupLeadFields" class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'wedding-anniversary-datepicker-edit-form'})">
              <label class="block input-label text-gray-600">Wedding Anniversary</label>
              <input :value="lead.formatted_wedding_anniversary ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>
          </div>

          <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'main_contact'})">
            <label class="block input-label text-gray-600">Main Contact</label>
            <input :value="lead.main_contact ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>

          <div class="flex items-end space-x-4 mt-3">
            <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'currently_insured'})">
              <label class="block input-label text-gray-600">Currently Insured</label>
              <input :value="lead.currently_insured_read_only ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>

            <div class="cursor-pointer w-full" @click="$emit('show-edit-mode', {fieldId: 'desired-start-datepicker-edit-form'})">
              <label class="block input-label text-gray-600">Desired Start</label>
              <input :value="lead.desired_start ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
            </div>
          </div>

          <div class="cursor-pointer w-full mt-3" @click="showSSNPasswordForm">
            <label class="block input-label text-gray-600">Social Security Number</label>
            <button class="btn btn-primary py-1 px-2" type="button">
              <svg aria-hidden="true" class="h-4 w-4" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path clip-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" fill-rule="evenodd"></path>
              </svg>
            </button>
          </div>

          <div v-if="lead.has_type_marked_as_medicare" class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'medicare_id'})">
            <label class="block input-label text-gray-600">Medicare ID</label>
            <input :value="lead.medicare_id ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>

          <div v-if="lead.has_type_marked_as_medicare" class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'part_a_effective_date'})">
            <label class="block input-label text-gray-600">Part A Effective Date</label>
            <input :value="lead.formatted_a_effective_date ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>

          <div v-if="lead.has_type_marked_as_medicare" class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'part_b_effective_date'})">
            <label class="block input-label text-gray-600">Part B Effective Date</label>
            <input :value="lead.formatted_b_effective_date ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>

          <div v-if="lead.has_type_marked_as_medicare" class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'part_d_effective_date'})">
            <label class="block input-label text-gray-600">Part D Effective Date</label>
            <input :value="lead.formatted_d_effective_date ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>

          <div v-if="lead.has_type_marked_as_medicare" class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'veteran'})">
            <label class="block input-label text-gray-600">Veteran</label>
            <input :value="lead.read_only_veteran ?? '&mdash;'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1" readonly type="text">
          </div>

          <!-- BEGIN: Group Type Lead Fields -->
          <div v-if="usesGroupLeadFields">
            <div class="flex items-end space-x-4 mt-3">
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'is_parent_lead'})">
                <label class="block input-label text-gray-600">Is This A Parent Lead?</label>
                <input :value="lead.is_parent_lead ? 'Yes' : 'No'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
              <div v-if="!lead.is_parent_lead" class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'parent_lead_id'})">
                <label class="block input-label text-gray-600">Parent Lead</label>
                <input :value="lead.parent_lead?.id ? `${lead.parent_lead.company}: ${lead.parent_lead.last_name}, ${lead.parent_lead.first_name}` : '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
            </div>

            <div class="flex items-end space-x-4 mt-3">
              <dl class="w-full">
                <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'notes'})">
                  <label class="input-label font-medium text-gray-600">Notes</label>
                  <input :value="lead.notes ?? '—'" class="input-field cursor-pointer border-gray-200 shadow-none mt-1 block"
                         readonly type="text">
                </div>
              </dl>
            </div>

            <div class="flex items-end space-x-4 mt-3">
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'sic_code'})">
                <label class="block input-label text-gray-600">SIC Code</label>
                <input :value="lead.sic_code ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'fein'})">
                <label class="block input-label text-gray-600">FEIN</label>
                <input :value="lead.fein ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
            </div>

            <div class="flex items-end space-x-4 mt-3">
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'company_type'})">
                <label class="block input-label text-gray-600">Company Type</label>
                <input :value="lead.company_type ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'tax_id'})">
                <label class="block input-label text-gray-600">Tax ID</label>
                <input :value="lead.tax_id ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
            </div>

            <div class="flex items-end space-x-4 mt-3">
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'waiting_period'})">
                <label class="block input-label text-gray-600">Waiting Period</label>
                <input :value="lead.waiting_period ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'hours_of_operation'})">
                <label class="block input-label text-gray-600">Hours of Operation</label>
                <input :value="lead.hours_of_operation ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
            </div>
            <div class="flex items-end space-x-4 mt-3">
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'employer_contribution_rate_employee'})">
                <label class="block input-label text-gray-600">Employer Contribution Rate (Employee)</label>
                <input :value="lead.employer_contribution_rate_employee ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'employer_contribution_rate_employee_and_spouse'})">
                <label class="block input-label text-gray-600">Employer Contribution Rate (Employee + Spouse)</label>
                <input :value="lead.employer_contribution_rate_employee_and_spouse ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
            </div>
            <div class="flex items-end space-x-4 mt-3">
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'employer_contribution_rate_employee_and_child'})">
                <label class="block input-label text-gray-600">Employer Contribution Rate (Employee + Child)</label>
                <input :value="lead.employer_contribution_rate_employee_and_child ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
              <div class="cursor-pointer w-full mt-3" @click="$emit('show-edit-mode', {fieldId: 'employer_contribution_rate_employee_and_family'})">
                <label class="block input-label text-gray-600">Employer Contribution Rate (Employee + Family)</label>
                <input :value="lead.employer_contribution_rate_employee_and_family ?? '&mdash;'" readonly class="input-field cursor-pointer border-gray-200 shadow-none mt-1" type="text">
              </div>
            </div>
          </div>
          <!-- END: Group Type Lead Fields -->
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import {PaperClipIcon, PhoneOutgoingIcon, ExternalLinkIcon} from '@heroicons/vue/solid'
import {PlusCircleIcon} from '@heroicons/vue/outline'

export default {
    name: "LeadViewInfoReadOnly",

    components: {
        PlusCircleIcon,
        PaperClipIcon,
        PhoneOutgoingIcon,
        ExternalLinkIcon,
    },

    props: {
        lead: {
          type: Object,
          required: true,
        },
        leadCurrentTime: {
          type: String,
        },
        clicktocall: {
          type: String,
          default: null,
        },
        hst_age_locked: Boolean,
    },

  inject: ['isDnc'],

  emits: ['show-edit-mode', 'show-compose-sms-form', 'show-compose-email-form', 'show-ssn-password-form'],

  computed: {
      usesGroupLeadFields() {
        return this.lead.has_type_marked_as_group;
      },
      clicktocallPhone1Route() {
          switch(this.clicktocall) {
              case 'TBComm':
                  return `callto://${this.lead.phone}`;
                  break;
              default:
                  return `callto:${this.lead.phone_2}`
          }
      },
      clicktocallPhone2Route() {
          switch(this.clicktocall) {
              case 'TBComm':
                  return `callto://${this.lead.phone_2}`;
                  break;
              default:
                  return `callto:${this.lead.phone_2}`
          }
      }
  },

  methods: {
      showComposeTextForm(payload) {
          this.$emit('show-compose-sms-form', {
              leadId: payload.leadId,
              phone: payload.phone,
          });
      },
      showComposeEmailForm(payload) {
          this.$emit('show-compose-email-form', {
              leadId: payload.leadId,
              email: payload.email,
          });
      },
      showSSNPasswordForm() {
        this.$emit('show-edit-mode', {fieldId: 'ssn'})
        this.$emit('show-ssn-password-form');
      },
  },
}
</script>
